'use client';
import { ROUTES } from '@constants/routes.constants';
import { ErrorPage } from '@ui/components';

export default function Error() {
  return (
    <ErrorPage
      title="404"
      type="404"
      message="This Is Not the Page You Are Looking For"
      buttonText="Go to Main"
      href={ROUTES.root}
    />
  );
}
